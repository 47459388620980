//Specific JS for this skin
require(['jquery'], function ($) {
    $('nav.nav-container:has(#nav)').clone().insertAfter('header#header');
    $('#header + .nav-container .mobnav-trigger-wrapper').attr('id', 'cat-nav');
    $('#cat-nav').insertBefore('.info-nav');

    $('#header + .nav-container #nav').removeClass('nav-regular');
    if (!$('#header + .nav-container #nav').hasClass('nav-mobile')) $('#header + .nav-container #nav').addClass('nav-mobile');
    if (!$('#header + .nav-container #nav').hasClass('acco')) $('#header + .nav-container #nav').addClass('acco');

    const mutationObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation["target"]["id"].includes('nav')) {
                $('#header + .nav-container #nav').removeClass('nav-regular');
                if (!$('#header + .nav-container #nav').hasClass('nav-mobile')) $('#header + .nav-container #nav').addClass('nav-mobile');
                if (!$('#header + .nav-container #nav').hasClass('acco')) $('#header + .nav-container #nav').addClass('acco');
            }
        });
    });

    mutationObserver.observe(document.querySelector('#header + .nav-container #nav'), {
        attributes: true,
    });

    const catNavMutationObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation["target"]["className"].includes('mobnav-trigger')) {
                $('#cat-nav > .mobnav-trigger').hasClass('active') ? $('#header + .nav-container').addClass('open') : $('#header + .nav-container').removeClass('open keepOpen');
            }
        });
    });

    catNavMutationObserver.observe(document.querySelector('#cat-nav > .mobnav-trigger'), {
        attributes: true,
    });
});
